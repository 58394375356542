<template>
  <el-dialog
    :title="'详情'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form disabled=true :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px">
    <el-row :gutter="24">
    <el-col :span="12">
    <el-form-item label="标题">
      <el-input
          v-model="dataForm.title">
      </el-input>
    </el-form-item>
    </el-col>
    </el-row>
        <el-row :gutter="24">
            <el-col :span="12">
                <el-form-item label="天气">
                    <el-input
                        v-model="dataForm.weather">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="温度（℃）">
                    <el-input
                        v-model="dataForm.temperature">
                    </el-input>
                </el-form-item>
            </el-col>
        </el-row>
    <el-form-item label="内容">
      <el-input
          :rows="8"
          v-model="dataForm.content"
          type="textarea">
      </el-input>
    </el-form-item>
        <!--<el-row :gutter="24">
            <el-col :span="12">
        <el-form-item label="创建人">
            <el-input
                v-model="dataForm.createBy">
            </el-input>
        </el-form-item>
            </el-col>
            <el-col :span="12">
        <el-form-item label="创建时间" >
            <el-input
                v-model="dataForm.createTime">
            </el-input>
        </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="24">
            <el-col :span="12">
                <el-form-item label="更新人">
                    <el-input
                        v-model="dataForm.updateBy">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="更新时间">
                    <el-input
                        v-model="dataForm.updateTime">
                    </el-input>
                </el-form-item>
            </el-col>
        </el-row>-->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            dataForm: {
                id: 0,
                title: '',
                content: '',
            },
        };
    },
    methods: {
        init (item) {
            this.dataForm = item;
            this.visible = true;
        },
    }
};
</script>
