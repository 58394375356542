<template>
    <div class="mod-config" style="margin: 20px 20px 20px 20px">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
                <el-input v-model="dataForm.key" placeholder="请输入标题名" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="getDataList()">查询</el-button>
                <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table
            :data="dataList"
            border
            v-loading="dataListLoading"
            @selection-change="selectionChangeHandle"
            style="width: 100%"
        >
            <el-table-column type="selection" header-align="center" align="center" width="50"> </el-table-column>
            <el-table-column prop="title" header-align="center" align="center" label="标题"> </el-table-column>
            <el-table-column prop="weather" header-align="center" align="center" label="天气"> </el-table-column>
            <el-table-column prop="temperature" header-align="center" align="center" label="温度（℃）">
            </el-table-column>
            <el-table-column prop="content" header-align="center" align="center" label="内容"> </el-table-column>
            <el-table-column prop="createBy" header-align="center" align="center" label="创建人"> </el-table-column>
            <!--<el-table-column
            prop="createTime"
            header-align="center"
            align="center"
            label="创建时间">
        </el-table-column>-->
            <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)">修改</el-button>
                    <el-button type="text" size="small" @click="deleteHandle(scope.row)">删除</el-button>
                    <el-button type="text" size="small" @click="info(scope.row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
        <info v-if="infoVisible" ref="info" @refreshDataList="getDataList"></info>
    </div>
</template>

<script>
import AddOrUpdate from './log-add-or-update';
import Info from './logInfo';
export default {
    data() {
        return {
            dataForm: {
                key: '',
            },
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false,
            infoVisible: false,
        };
    },
    components: {
        AddOrUpdate,
        Info,
    },
    activated() {
        this.getDataList();
    },
    methods: {
        info(item) {
            this.infoVisible = true;
            this.$nextTick(() => {
                this.$refs.info.init(item);
            });
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            let params = {
                current: this.pageIndex,
                limit: this.pageSize,
                key: this.dataForm.key,
                startTime: this.$dayjs().subtract(1, 'year').format('YYYY-MM-DD HH:mm'),
                endTime: this.$dayjs().format('YYYY-MM-DD HH:mm'),
            };
            this.$client.getLogList(params).then((data) => {
                this.dataList = data.data.records;
                this.totalPage = data.data.total;
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList();
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 新增 / 修改
        addOrUpdateHandle(item) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(item);
            });
        },
        // 删除
        deleteHandle(row) {
            let ids = [];
            ids.push(row.id);
            this.$confirm(`确定对标题为${row.title}的日志进行删除操作?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$client.deleteLog(ids).then((data) => {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500,
                    });
                    this.getDataList();
                });
            });
        },
    },
    created() {
        this.getDataList();
    },
};
</script>
