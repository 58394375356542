<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px">
    <el-form-item label="标题" prop="title" style="color: #ffffff">
      <el-input
          style="width: 40%; "
          v-model="dataForm.title"
          type="textarea"
          placeholder="标题">
      </el-input>
    </el-form-item>
        <el-form-item label="天气">
            <el-input
                style="width: 40%"
                v-model="dataForm.weather"
                placeholder="天气">
            </el-input>
        </el-form-item>
        <el-form-item label="温度（℃）">
            <el-input
                style="width: 40%"
                v-model="dataForm.temperature"
                placeholder="温度（℃）">
            </el-input>
        </el-form-item>
    <el-form-item label="内容" prop="content">
      <el-input
          v-model="dataForm.content"
          placeholder="内容"
          :rows="8"
          type="textarea">
      </el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="saveOrUpdata()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            dataForm: {
                id: 0,
                title: '',
                content: '',
                temperature: '',
                weather: '',
            },
            dataRule: {
                title: [{required: true, message: '请填写标题', trigger: 'blur'}],
                content: [{required: true, message: '请填写内容', trigger: 'blur'}],
            }
        };
    },
    methods: {
        init (item) {
            if (item) {
                this.dataForm = item;
            } else {
                this.dataForm = {
                    id: 0,
                    title: '',
                    content: '',
                    temperature: '',
                    weather: '',
                };
            }
            this.visible = true;
        },
        saveOrUpdata() {
            if (this.dataForm.id > 0) {
                this.updataLog();
            } else {
                this.dataFormSubmit();
            }
        },
        // 表单提交
        updataLog () {
            this.$confirm(`确定修改本条日志?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$refs.dataForm.validate((valid) => {
                    if (valid) {
                        this.$client.updataLog(this.dataForm.id, this.dataForm).then((data) => {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500
                            });
                            this.visible = false;
                            this.$emit('refreshDataList');
                        });
                    }
                });
            });
        },
        dataFormSubmit () {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    this.$confirm(`确定保存本条日志?`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$client.saveLog(this.dataForm).then((data) => {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500
                            });
                            this.visible = false;
                            this.$emit('refreshDataList');
                        });
                    });
                }
            });
        }
    }
};
</script>
<style>
    .el-textarea__inner, .el-input_inner {
        font-size: 14px;
        color: #d1d1d1;
    }
</style>
